import Dao from './dao';
import { WorkshopRoster, ArrivalDeparture } from '@/model/report';
import _ from 'underscore';
import { sprintf } from 'sprintf-js';

class ReportDao extends Dao {
    async getWorkshopRoster(criteria) {
        try {
            const response = await this.api.post(sprintf('report/workshop-roster/search?rev=%d', this.getRev()), criteria);
            return _.map(response.data, WorkshopRoster.create);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading workshop roster: %s', err.data.message);
            throw (new Error(message));
        }
    }

    async getArrivalDeparture(criteria) {
        try {
            const response = await this.api.post(sprintf('report/arrival-departure-search?rev=%d', this.getRev()), criteria);
            return _.map(response.data, ArrivalDeparture.create);
        }
        catch (err) {
            console.error(err);
            const message = sprintf('Error loading arrival/departure: %s', err.data.message);
            throw (new Error(message));
        }
    }

}

const reportDao = new ReportDao();
export default reportDao;