import _ from 'underscore';
import formatters from '../util/formatters';
import { mkDate, date, DEFAULT_DATE_FORMAT } from '@/util/formatters';
import { format } from 'date-fns';
import { sprintf } from 'sprintf-js';

export const Lookback = {
  ONE_DAY: {
    idx: 1,
    label: '1 day',
    value: { days: 1 }
  },
  TWO_DAYS: {
    idx: 2,
    label: '2 days',
    value: { days: 2 }
  },
  THREE_DAYS: {
    idx: 3,
    label: '3 days',
    value: { days: 3 }
  },
  FOUR_DAYS: {
    idx: 4,
    label: '4 days',
    value: { days: 4 }
  },
  FIVE_DAYS: {
    idx: 5,
    label: '5 days',
    value: { days: 5 }
  },
  ONE_WEEK: {
    idx: 6,
    label: '1 week',
    value: { weeks: 1 }
  },
  TWO_WEEKS: {
    idx: 7,
    label: '2 weeks',
    value: { weeks: 2 }
  },
  THREE_WEEKS: {
    idx: 8,
    label: '3 weeks',
    value: { weeks: 3 }
  },
  ONE_MONTH: {
    idx: 8,
    label: '1 month',
    value: { months: 1 }
  }
};
export const LOOKBACKS = _.keys(Lookback).map((key) => Lookback[key]);

export class VaccineRegistrationExceptionHistoryCriteria {
  fromDate = null;
  toDate = null;
  lastName = null;
  ubcId = null;
  user = null;

  get fields() {
    return ['fromDate', 'toDate', 'lastName', 'ubcId', 'user'];
  }

  clear() {
    _.forEach(this.fields, (f) => (this[f] = null));
  }

  constructor() {
    this.clear();
  }

  serialize() {
    return _.reduce(
      this.fields,
      (json, f) => {
        switch (f) {
          case 'ubcId':
            json[f] = formatters.serializeAsUbcId(this.ubcId);
            break;
          case 'fromDate':
            json[f] = _.isNull(this[f]) ? null : date(this[f], DEFAULT_DATE_FORMAT) + ' 00:00:00';
            break;
          case 'toDate':
            json[f] = _.isNull(this[f]) ? null : date(this[f], DEFAULT_DATE_FORMAT) + ' 23:59:59';
            break;
          default:
            json[f] = this[f];
            break;
        }
        return json;
      },
      {}
    );
  }
}

export class WorkshopCriteria {
  sessionDateRange = [null, null];
  workshopIds = [];
  sessionIds = [];
  organizationType = null;
  organizationIds = [];
  districtIds = [];
  ubcId = null;
  firstname = null;
  lastname = null;

  constructor() {
    this.clear();
  }

  get fields() {
    return ['sessionDateRange', 'workshopIds', 'sessionIds', 'organizationType', 'organizationIds', 'districtIds', 'ubcId', 'firstname', 'lastname'];
  }

  clear() {
    _.each(this.fields, (f) => {
      if (f.endsWith('Range')) {
        this[f] = [null, null];
      } else if (f.endsWith('Ids')) {
        this[f] = [];
      } else {
        this[f] = null;
      }
    });
  }

  isEmpty() {
    return _.reduce(
      this.fields,
      (result, field) => {
        if (field.endsWith('Range')) {
          return result && this[field][0] === null && this[field][1] === null;
        } else if (field.endsWith('Ids')) {
          return result && this[field].length === 0;
        } else {
          this[field] = !!this[field] ? this[field] : null; // set falsy values to null
          return result && this[field] === null;
        }
      },
      true
    );
  }

  serialize() {
    return _.reduce(
      this.fields,
      (json, f) => {
        switch (f) {
          case 'ubcId':
            json[f] = formatters.serializeAsUbcId(this.ubcId);
            break;
          default:
            json[f] = this[f];
            break;
        }

        return json;
      },
      {}
    );
  }
}

export class WorkshopRosterCriteria extends WorkshopCriteria {
  includeAttendeeName = true;
  includeUbcId = true;
  includeVaccineDetails = true;
  includeTitle = true;
  includeCityState = true;
  includeWorkshop = true; //Note, this isn't connected to report inclusion in backend per CITF
  includeStartDate = true; //Note, this isn't connected to report inclusion in backend per CITF
  includeEndDate = true; //Note, this isn't connected to report inclusion in backend per CITF
  includeRegistrationType = true;
  includeOrganization = true;
  includeLocal = true;
  includeUnionStatus = true;
  includeCouncil = true;
  includeDistrict = true;
  includeAttendeeEmail = true;
  includeDateRegistered = true;
  includeRegisteredBy = true;
  includeMobilePhone = true;
  includeHasPicture = true;
  includeUsername = true;
  includeMentorName = true;
  includeMentorEmail = true;
  includeContractor = true;
  includeContractorFirstName = true;
  includeContractorLastName = true;
  includeContractorTitle = true;
  includeContractorPhone = true;
  includeContractorEmail = true;

  constructor() {
    super();
    this.resetFlagFields();
  }

  get activeFlagFields() {
    //These flag fields are used in report field selection
    return [
      'includeAttendeeName',
      'includeUbcId',
      'includeVaccineDetails',
      'includeTitle',
      'includeCityState',
      'includeRegistrationType',
      'includeOrganization',
      'includeLocal',
      'includeUnionStatus',
      'includeCouncil',
      'includeDistrict',
      'includeAttendeeEmail',
      'includeDateRegistered',
      'includeRegisteredBy',
      'includeMobilePhone',
      'includeHasPicture',
      'includeMentorName',
      'includeMentorEmail',
      'includeUsername',
      'includeContractor',
      'includeContractorFirstName',
      'includeContractorLastName',
      'includeContractorTitle',
      'includeContractorPhone',
      'includeContractorEmail'
    ];
  }

  get defaultFlagFields() {
    //These flag fields are NOT used in report field selection
    return ['includeWorkshop', 'includeStartDate', 'includeEndDate'];
  }

  get flagFields() {
    return [...this.activeFlagFields, ...this.defaultFlagFields];
  }

  get fields() {
    return [...super.fields, ...this.flagFields];
  }

  setActiveFlagFields(value) {
    _(this.activeFlagFields).each((f) => (this[f] = value));
  }

  setDefaultFlagFields(value) {
    _(this.defaultFlagFields).each((f) => (this[f] = value));
  }

  resetFlagFields() {
    //Include all fields
    this.setActiveFlagFields(true);
    this.setDefaultFlagFields(true);
  }

  clear() {
    super.clear();
    this.resetFlagFields();
  }

  get isValid() {
    //At least one of the NON-WORKSHOP flag fields must be selected for report creation
    return _(this.activeFlagFields).any((f) => this[f]);
  }
}

export class WorkshopRoster {
  static create(roster) {
    return new WorkshopRoster(roster);
  }

  fullname;
  ubcId;
  title;
  cityState;
  workshopName;
  startDate;
  endDate;
  registrationType;
  organizationName;
  affiliate;
  unionStatus;
  council;
  district;
  email;
  registeredDate;
  registeredBy;
  vaccineVerified;
  vaccineVerifiedByUsername;
  vaccineVerifiedDate;
  requireVaccine;
  mobilePhone;
  username;
  mentorName;
  mentorEmail;
  hasPicture;
  contractor;
  contractorFirstName;
  contractorLastName;
  contractorTitle;
  contractorPhone;
  contractorEmail;

  constructor(roster) {
    this.copyFrom(roster);
  }

  get fields() {
    return [
      'fullname',
      'ubcId',
      'title',
      'cityState',
      'workshopName',
      'startDate',
      'endDate',
      'registrationType',
      'organizationName',
      'affiliate',
      'unionStatus',
      'council',
      'district',
      'email',
      'registeredDate',
      'registeredBy',
      'vaccineVerified',
      'vaccineVerifiedByUsername',
      'vaccineVerifiedDate',
      'requireVaccine',
      'mobilePhone',
      'username',
      'mentorName',
      'mentorEmail',
      'hasPicture',
      'contractor',
      'contractorFirstName',
      'contractorLastName',
      'contractorTitle',
      'contractorPhone',
      'contractorEmail'
    ];
  }

  copyFrom(roster) {
    _(this.fields).each((f) => (this[f] = roster[f]));
  }

  get vaccineDetails() {
    if (!this.requireVaccine) {
      return 'N/A';
    } else if (this.vaccineVerified) {
      const date = format(mkDate(this.vaccineVerifiedDate), 'MM/dd/yyyy') || '';
      const username = sprintf(' (%s)', this.vaccineVerifiedByUsername) || '';
      return sprintf('%s%s', date, username);
    } else {
      return '';
    }
  }
}

export class ArrivalDeparture {
  static create(ad) {
    return new ArrivalDeparture(ad);
  }

  registrationId;
  arriving; // date-time
  departing; // date-time
  prerequisite;
  comments;
  status;
  fullname;
  location;
  ubcid;
  type;
  workshopName;
  stateDate; // date
  endDate; // date
  organization;
  district;
  roomRequest;
  flightRequest;
  vaccineVerified;
  vaccineVerifiedByUsername;
  vaccineVerifiedDate;
  requireVaccine;

  constructor(ad) {
    this.copyFrom(ad);
  }

  get fields() {
    return [
      'registrationId',
      'arriving',
      'departing',
      'prerequisite',
      'comments',
      'status',
      'fullname',
      'location',
      'ubcid',
      'type',
      'workshopName',
      'startDate',
      'endDate',
      'organization',
      'district',
      'roomRequest',
      'flightRequest',
      'vaccineVerified',
      'vaccineVerifiedByUsername',
      'vaccineVerifiedDate',
      'requireVaccine'
    ];
  }

  get vaccineDetails() {
    if (!this.requireVaccine) {
      return 'N/A';
    } else if (this.vaccineVerified) {
      const date = format(mkDate(this.vaccineVerifiedDate), 'MM/dd/yyyy') || '';
      const username = sprintf(' (%s)', this.vaccineVerifiedByUsername) || '';
      return sprintf('%s%s', date, username);
    } else {
      return '';
    }
  }

  copyFrom(ad) {
    _.each(this.fields, (f) => {
      switch (f) {
        case 'arriving':
        case 'departing':
        case 'startDate':
        case 'endDate':
        case 'vaccineVerifiedDate':
          this[f] = mkDate(ad[f]);
          break;
        default:
          this[f] = ad[f];
      }
    });
  }
}
